"use strict";

define(['R', 'jquery'], function(R, $)
{
    class mLogin extends R.core.Module {
        constructor() {
            super();
        }

        init(el){
            if (!super.init()) {
                this.$mod = $(el);
                R.regMod(this);

                let win_wrap = document.getElementById('win_wrap');

                this.$elModal = $('#m-Login-loginModal');
                win_wrap.appendChild(this.$elModal[0]);

                this.$elForgot = $('#m-Login-forgotModal');
                win_wrap.appendChild(this.$elForgot[0]);

                this.$elForgotSuccess = $('#m-Login-forgotSuccessModal');
                win_wrap.appendChild(this.$elForgotSuccess[0]);

                R.addStaticModule('login');

                this.initFormLogin();
                this.initFormForgot();

                if (window.location.hash == '#login') {
                    this.$elModal.modal('show');
                }

                this.setListeners();
            }
            this.start();
        }

        initFormLogin() {
            let $form = $('form', this.$elModal);

            class mLoginFormLogin extends R.core.Form {
                constructor(form, rules) {
                    super(form, rules);
                }

                submitForm(data) {
                    if (this.$submit_btn.hasClass('loading')) return;
                    super.submitForm(data);
                }

                _submitForm(opts) {
                    this.$submit_btn.addClass('loading');
                    super._submitForm(opts);
                }

                ajaxComplete() {

                }

                ajaxError() {
                    this.$submit_btn.removeClass('loading');
                }

                ajaxSuccess(data) {
                    if (data && data['ok'] != undefined) {
                        if (data['url'] == 'reload'){
                            window.localStorage.setItem('isAuth', true)
                            window.location.href = R.R.site.baseHref + 'index'
                            // window.location.reload();
                            return;
                        }
                    }
                    if (data && data.error) {
                        let text = 'System error'
                        switch (data.desc) {
                            case 'already_logged':
                                window.localStorage.setItem('isAuth', true)
                                window.location.href = R.R.site.baseHref + 'index'
                                return
                                text = 'Already logged in'
                                break;
                            case 'user_blocked':
                                text = 'User is blocked'
                                break;
                            case 'email_pass_invalid':
                            case 'email_pass_incorrect':
                                text = 'Credentials are incorrect'
                                break;
                            case 'account_inactive':
                                text = 'User is disabled'
                                break;
                        }

                        R.notify(R.text(text), 'error')
                    }
                    this.$submit_btn.removeClass('loading');
                }
            }

            this.formObjLogin = new mLoginFormLogin($form, {
                login: ['required', {min_length: 4}],
                pass: ['required', {min_length: 4}]
            });
        }

        initFormForgot() {
            let $form = $('form', this.$elForgot);

            class mLoginFormForgot extends R.core.Form {
                constructor(form, rules, opts) {
                    super(form, rules);

                    this.opts = opts || {};
                }

                submitForm(data) {
                    if (this.$submit_btn.hasClass('loading')) return;
                    super.submitForm(data);
                }

                _submitForm(opts) {
                    this.$submit_btn.addClass('loading');
                    super._submitForm(opts);
                }

                ajaxComplete() {

                }

                ajaxError() {
                    this.$submit_btn.removeClass('loading');
                }

                ajaxSuccess(data) {
                    if (data['ok'] != undefined) {
                        if (typeof this.opts.onSuccess == 'function') this.opts.onSuccess(data);
                    }
                    if (data.error) {
                        let text = R.text('System error');
                        if (data.desc) {
                            switch (data.desc) {
                                case "no_user_found":
                                    text = R.text("No user found");
                                    break;
                                case "too_many_restores":
                                    text = R.text("Too many tries, please try again later");
                                    break;
                            }
                        }
                        R.notify(text, 'error');
                    }
                    this.$submit_btn.removeClass('loading');
                }
            }

            this.formObjForgot = new mLoginFormForgot($form, {
                login: ['required', {min_length: 4}]
            }, {
                onSuccess : ()=>{
                    this.$elForgot.modal('hide');
                    this.$elForgotSuccess.modal('show');
                }
            });
        }

        setListeners() {
            this.$elModal
                .on('click', '[data-target="#m-Login-forgotModal"]', (e)=>{
                    e.stop();

                    let bodyStyle = window.document.body.style,
                        temp = bodyStyle.paddingRight;

                    this.$elModal.one('hidden.bs.modal', () => { this.$elForgot.modal('show') })

                    this.$elModal.modal('hide');
                    // bodyStyle.paddingRight = '';
                    // this.$elForgot.modal('show');
                    // bodyStyle.paddingRight = temp;

                })
            ;
        }

        start() {
            super.start();
            this.play()
        }

        play() {
            super.play();
        }
    }
    R.regModCls(mLogin);

    define("m-login", [], function() {return mLogin;});

    return mLogin;
});